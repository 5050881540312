import React from 'react'
import GlobalStyle from '../components/global-style'
import Background from '../components/background'
import Header from '../components/header'
import TwoColumns from '../components/two-columns'
import styled from 'styled-components'
import SideBar from '../components/side-bar'
import Filter from '../components/filter'
import Attorney from './attorney'
import { mmddyyyyFormatter, onMobile } from '../utils'
import { Topic } from '../types'
import SEO from '../components/seo'

interface Props {
  pageContext: {
    topic: Topic
  }
}

export default function DetailPage({ pageContext }: Props) {
  const {
    title,
    link,
    lawyer,
    tags,
    dateString,
    description,
  } = pageContext.topic

  const innerHTML = description === 'nan' ? '' : description

  return (
    <>
      <SEO title={title} description={description.slice(0, 150)} />
      <GlobalStyle />
      <Background />
      <Header />
      <TwoColumns>
        <Container>
          <div style={{ padding: '0 28px' }}>
            <Title>
              <span>[{mmddyyyyFormatter(dateString)}]</span>
              <span> </span>
              <u>{title}</u>
            </Title>
            <Extra>发表于 {mmddyyyyFormatter(dateString)}</Extra>
          </div>
          <Filter source={tags || []} />
          {link && link !== 'nan' ? <Video dangerouslySetInnerHTML={{ __html: link }} /> : ''}
          <Description>
            <div dangerouslySetInnerHTML={{__html: innerHTML}}/>
          </Description>
          {lawyer != null && <Attorney source={lawyer} />}
        </Container>
        <SideBar />
      </TwoColumns>
    </>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 700px;
  background: white;
  box-sizing: border-box;
  padding-top: 14px;
`

const Video = styled.div`
  justify-content: center;
  align-items: center;
  padding: 14px 28px;

  > iframe {
    height: 360px;
    width: 100%;

    ${onMobile} {
      height: 220px;
    }
  }
`

const Description = styled.div`
  padding: 14px 28px;
  color: #666;
  font-size: 14px;
  line-height: 1.5em;
  img {
    display: block;
    max-width: 100%;
  }
`

const Title = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: #ff9234;
  display: block;
`

const Extra = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #c4c4c4;
  margin-top: 12px;
`
