import React from 'react'
import styled from 'styled-components'
import Icon from '../components/icon'
import { Link } from 'gatsby'
import { Lawyer } from '../types'
import { onMobile } from '../utils'
import { isPresent } from '../utils'

interface Props {
  source: Lawyer
}

export default function Attorney({ source }: Props) {
  return (
    <Container>
      <Card to={source.link} target="_blank">
        <Portrait style={{ backgroundImage: `url("${source.photo}")` }}/>
        <Detail>
          {isPresent(source.name) && (<Info>
            <Icon name="confirmed"/>
            <Name>
              <u>{source.name}</u>
              <Icon name="quote"/>
            </Name>
          </Info>)}

          {isPresent(source.lawfirm) && (<Info>
            <Icon name="confirmed"/>
            <u>@{source.lawfirm}</u>
          </Info>)}

          {isPresent(source.intro) && (<Brief>{source.intro}</Brief>)}
        </Detail>
      </Card>
    </Container>
  )
}

const Container = styled.div`
  background: white;
  border-top: 1px solid #c4c4c4;
  padding: 28px 56px;

  ${onMobile} {
    padding: 12px 24px;
  }
`

const Card = styled(Link)`
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  height: 200px;
  overflow: hidden;
`

const Portrait = styled.div`
  width: 180px;
  height: 200px;
  background: transparent no-repeat center center;
  background-size: cover;
`

const Detail = styled.div`
  padding: 14px 18px;
  flex: 1;
`

const Info = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #3a3f45;
  flex-direction: row;
  align-items: center;

  > * + * {
    margin-left: 8px;
  }
`

const Brief = styled.div`
  margin-top: 24px;
  font-size: 12px;
  line-height: 16px;
  color: #3a3f45;
  flex: 1;
  overflow: hidden;
`

const Name = styled.div`
  flex-direction: row;
  align-items: center;

  > * + * {
    margin-left: 8px;
  }
`
